import React, { useCallback, useContext } from "react";
import { withRouter, Redirect } from "react-router";
import app from "../FirebaseConfig";
import { AuthContext } from "../components/Authentication/Auth";
import './Login.css'
import '../Animations.css'

import Button from '../components/Button/Button';

const Login = ({ history }) => {
  const handleLogin = useCallback(
    async event => {
      event.preventDefault();
      const { email, password } = event.target.elements;
      try {
        await app
          .auth()
          .signInWithEmailAndPassword(email.value, password.value);

        history.push("/assessment");
      } catch (error) {
        alert("Lo sentimos, \nEl usuario y/o la contraseña no son correctos. \nInténtalo nuevamente.");
      }
    },
    [history]
  );

  const { currentUser } = useContext(AuthContext);

  if (currentUser) {
    return <Redirect to="/assessment" />;
  }

  return (
    <div className="wrapper fadeInDown">
      <div id="formContent">
        <div className="fadeIn first">
          <img src={`${process.env.PUBLIC_URL}/Images/Logotipo_Scenario.png`} id="icon" alt="User Icon" />
          {/*<h2>Assessment VR</h2>*/}
          <p >
            La experiencia virtual para
            desarrollar y aplicar evaluaciones de
            comportamientos ante situaciones inesperadas.
          </p>
        </div>

        <form className="forUser" onSubmit={handleLogin}>
          <input className="fadeIn second"  name="email" type="email" placeholder="Correo Electrónico"/>
          <input className="fadeIn third"  name="password" type="password" placeholder="Contraseña"/>
          <Button text="Iniciar Sesión" margin=".5rem"></Button>
          {/*<a className="underlineHover" href="#">Crear una cuenta</a>*/}
        </form>

        <div id="formFooter">
          
          <p className="mb-0 mx-3 font-weight-light">Powered By</p>
          <img src={`${process.env.PUBLIC_URL}/Images/LogoKaleido.png`} height={80} />
        </div>

      </div>
    </div>
    
  );
};

export default withRouter(Login);