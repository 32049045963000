
import {
	HashRouter as Router,
	Switch,
	Route,
  Redirect
} from 'react-router-dom';
import './App.css';

//Auth provider
import {AuthProvider} from './components/Authentication/Auth';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';

//views
import Login from './views/Login';
import Assessment from './views/Assessment';
import NotFound404 from './views/NotFound404';

function App() {
  return (
    <AuthProvider>
      <Router>
        <div className="App">
            <Switch>
              <Redirect exact from="/" to="/Login" />
              <Route exact path="/Login" component={Login}/>
              <PrivateRoute exact path="/assessment" component={Assessment}/>
              <Route path="*"> <NotFound404 /> </Route>
            </Switch>  
        </div>
      </Router>
    </AuthProvider>
    
  );
}

export default App;
