import React, { useState, useCallback, useRef, useContext, useImperativeHandle, forwardRef } from 'react';
import Webcam from 'react-webcam';
import './Camera.css'
import '../../Animations.css'

import app from '../../FirebaseConfig';
import { AuthContext } from "../Authentication/Auth"; 

const Camera = forwardRef(({setEtapaGrabacion, finish, setUploading, setCapturing, timeFinalized, moment }, ref) => {
    /* videoCapturing */
	const webcamRef = useRef(null);
    const mediaRecorderRef = useRef(null);

    const [startCapture, setStartCapture] = useState(false);
    const [recordedChunks, setRecordedChunks] = useState([]);
    //const [uploading, setUploading] = useState(false);

    /* firebase  */
	const storage = app.storage();
	const database = app.database();
    const { currentUser } = useContext(AuthContext);

    const handleStartCaptureClick = useCallback(() => {
        if(!startCapture){
            if(webcamRef.current.stream){
                console.log("Comenzando a grabar")
                setStartCapture(true);
                setCapturing(true);
                
                mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
                mimeType: "video/webm"
                });
                mediaRecorderRef.current.addEventListener(
                "dataavailable",
                handleDataAvailable
                );
                mediaRecorderRef.current.start();
                //setTalking(true);
            }else{
                alert("Cámara no encontrada, \nPor favor verifique si ha aceptado los permisos del navegador. \nEspere unos segundos a que la cámara haya cargado completamente antes de presionar el botón 'Iniciar grabación'");
            }
            
        }
        else{
            console.log("Reanudando")
            mediaRecorderRef.current.resume();
            setCapturing(true);
        }
        
    }, [webcamRef, setCapturing, startCapture, mediaRecorderRef]);
    

    const handleDataAvailable = useCallback(
        ({ data }) => {
            if (data.size > 0) {
                setRecordedChunks((prev) => prev.concat(data));
            }
        },
        [setRecordedChunks]
    );


    const handleStopCaptureClick = useCallback(() => {
        if(timeFinalized){
            console.log("stop")
            mediaRecorderRef.current.stop();
            //setTalking(false);
            setCapturing(false);
            setEtapaGrabacion(true);
        }else{
            console.log("video pausado")
            mediaRecorderRef.current.pause();
            setCapturing(false);
        }
        
    }, [mediaRecorderRef, webcamRef,timeFinalized, setCapturing]);



    const handleSend = useCallback(() => {
        setUploading(true);
        if (recordedChunks.length) {
            const blob = new Blob(recordedChunks, {
            type: "video/webm" });

            /* send data to firebase */
			const storageRef = storage.ref(
                `/videos/${currentUser.uid}/momento${moment+1}/video.webm`
			);
			storageRef.put(blob).then(function (snapshot) {
				snapshot.ref.getDownloadURL().then((downloadURL) => {
					//console.log(downloadURL);
                    database.ref(
                        `respuestas/${currentUser.uid}/momento${moment+1}/`
                    ).update({video: downloadURL});
                    
                    database.ref(
                        `respuestas/${currentUser.uid}`
                    ).update({progreso: moment+1});
				});
				console.log('Uploaded a blob or file!');
                
                finish(true);
			});

			/* end send data to firebase */
        }
    }, [recordedChunks]);

    const videoConstraints = {
        width: 640,
        height: 360,
        facingMode:"user"//{ exact: "environment" }//"user"
    };



    useImperativeHandle(ref, () => ({

        startRecording: ()=>{
            handleStartCaptureClick()
        },

        stopRecording: ()=>{
            handleStopCaptureClick()
        },

        sendInformation: ()=> {
            handleSend();
        }
    
    }));

    
        return (
            <div className ="camera-container">
                <Webcam
                    id="camera"
                    className="embed-responsive mt fadeIn first"
                    audio={true}
                    ref={webcamRef}
                    videoConstraints={videoConstraints}
                    mirrored = {true}
                    //style={{ maxHeight: '60vh', borderRadius:"1rem"}}
                />
    
                {/*<Button 
                    text={capturing? "Detener Grabación " : "Iniciar Grabación"} 
                    onClick={capturing? handleStopCaptureClick : handleStartCaptureClick} 
                    margin="1rem" color="#b23b3b"></Button>
                {/*recordedChunks.length > 0 && (
                    <Button disabled={capturing} text="Enviar y continuar" onClick={handleSend}></Button>
                )*/}
                
            </div>
        );
    
})
  
export default Camera;