import React, { useEffect, useState } from 'react';
import Button from '../Button/Button';
import './InstructionsModal.css'


const InstructionsModal = ({setFinal})=> {


    const [stepsCount, setStepsCount] = useState(0);
    const steps=[
        {id: "item1", text:`<div>Elementos de la ventana de inicio <br></br>
            <b>1)	Entiende la misión del caso:</b> Texto que explica de manera general cuál es el desafío que debes superar.<br>
            <b>2)	Comprende el objetivo del momento:</b> Es la meta que debes lograr en cada una de las interacciones que tienes en el assessment. La misión está conformada por múltiples momentos.<br>
            <b>3)	Interactúa con el miembro del equipo:</b> El avatar representa al miembro del equipo que te va realizar preguntas, te planteará objeciones o te planteará retos a resolver.<br>
            <b>4)	Temporizador:</b> Indicador del tiempo mínimo que debes permanecer desarrollando cada actividad.<br>
            <b>5)	Inventario:</b> Sección en donde se despliega una lista de ítems que puedes utilizar en la solución del reto.<br>
            <b>6)	Botón continuar:</b> Haga clic en el botón para avanzar al siguiente momento.<br>
            <b>7)	Botón pausa:</b> Botón para pausar o reanudar la cuenta del temporizador, se reanudará automáticamente si se presionar continuar<br>
            </div>`, image: process.env.PUBLIC_URL+"/Images/Instructions/step1.png"},
        {id: "item3", text: `<div>Interacción con el miembro del equipo <br></br>
            <b>1)	</b> Escucha la pregunta o la intervención que le haga el miembro del equipo.<br>
            <b>2)	</b> Da permiso para que se active la cámara y el micrófono. Para argumentar las respuestas se hace uso de la cámara y micrófono. Por ende, es importante aceptar los permisos del navegador.<br>
            <b>3)	</b> Asegúrate de que la cámara te este enfocando. <br>
            <b>4)	</b> Da clic en el botón iniciar grabación.<br>
            <b>5)	</b> Una vez hayas completado tu respuesta da clic en detener grabación. <br>
            </div>`, image: process.env.PUBLIC_URL+"/Images/Instructions/step3.png"},
        {id: "item5", text: `<div>El temporizador indicará el tiempo de duración de cada momento, este tiempo se debe cumplir como mínimo para poder continuar, una vez cumplido con el tiempo y el objetivo, se debe presionar el botón enviar y continuar, para guardar las respuestas y pasar al siguiente momento.<br></br>
            Recuerda que para cumplir con el objetivo se debe tener la aprobación de los miembros del equipo (Sofía y Roberta), por eso es recomendable grabar respuestas lo suficientemente completas ya que Sofía y Roberta no se conforman con respuestas cortas!. Por otro lado, los diálogos de los avatares son limitados, por eso es posible que se vuelvan a repetir si no se ha cumplido el tiempo mínimo de cada actividad, en ese caso sigue ampliando tu respuesta.<br></br>
            Al momento de enviar una respuesta debes esperar unos segundos para poder continuar con la evaluación. Procura no interrumpir el proceso para que tus respuestas se guarden correctamente.
            </div>`, image: process.env.PUBLIC_URL+"/Images/Instructions/step5.png"}
    ]
    
    useEffect(() => {
        //console.log(steps[0].image)

    }, []);
    

    const updateStep = ()=>{
        if(stepsCount < steps.length-1){
            document.getElementById("principal-section").scrollTop = 0;
            setStepsCount(stepsCount+1)
        }else{
            //$('#InstructionsModal').modal('hide')
            setFinal(true)
        }
        
    }

    const goBack = ()=>{
        if(stepsCount > 0){
            //setvideoEnded(false);
            setStepsCount(stepsCount-1);
            console.log("regresando...")
        }
    }

    return (
        <div className="d-flex flex-column align-items-center" style={{width:"100%", height:"100%" }}>
            <h3 className="text-center m-3" style={{color:'rgb(69, 123, 157)'}} id="InstructionsModalLabel">Instrucciones</h3>
            
            <div style={{height:"80%", width: "80%", 
                display:'flex', justifyContent:'center', alignItems:'center', 
                border: '#b23b3b 1px solid', borderRadius: '1rem'}}>
                
                <div id="principal-section" >

                    <div className="modal-body" style={{padding: "2rem", textAlign:"justify"}}>
                        {<div dangerouslySetInnerHTML={{__html: steps[stepsCount].text}}></div> }
                        {steps[stepsCount].image?
                            <img style={{width:"100%", height:"auto", marginTop:"2rem"}} src={steps[stepsCount].image} alt="Imagen" />
                            :<div></div>}
                    </div>

                </div>

            </div>
            

            <div className="m-3 w-75">
                {stepsCount>0&&<Button text="Regresar" margin="0rem 1rem" padding="10px 20px" onClick={goBack} color="#b23b3b"></Button>}
                <Button text={stepsCount>=steps.length-1?"Empezar":"Siguiente"} margin="0rem 1rem" padding="10px 20px" onClick={updateStep}></Button>
            </div>
        </div>

        
    );
}
  
export default InstructionsModal;