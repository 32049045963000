import React, { useContext } from "react";
import app from '../../FirebaseConfig';
import { AuthContext } from "../Authentication/Auth"; 
import Button from '../Button/Button'

import '../../Animations.css'
import './Header.css'

function Header() {
    const { currentUser } = useContext(AuthContext); //currentUser.email - currentUser.uid
    return (
        <nav className="navbar navbar-light fadeInDown" style={{backgroundColor: "#457b9d"}}>
            <a className="navbar-brand" href="/#">
                <img className="logo-header mr-3" src={`${process.env.PUBLIC_URL}/Images/logogerencia_blanco.png`} height="45" alt=""/> 
                <img className="logo-header" src={`${process.env.PUBLIC_URL}/Images/Logotipo_Scenario_blanco.png`} height="50" alt=""/>
            </a>
            
            
            <form className="form-inline">
                {/*<a className="nav-link " >
                    {currentUser.email}
                        </a>*/}
                <p className="h6 font-weight-light" style={{color:"white"}}>{currentUser.email}</p>
                <Button text="Cerrar sesión" color="#b23b3b" margin="0" padding="10px 20px" onClick={()=>app.auth().signOut()}></Button>
            </form>
            
        </nav>
            
    );
}
  
export default Header;