import React, { useEffect, useState } from 'react';
import './InventoryItem.css'

const InevntoryItem = ({id,image, tittle, onChange, disable, index})=> {
    const [selected, setSelected] = useState(false);

    const Style = {
        backgroundSize: "contain",
        backgroundImage:"url("+image+")", //`url(${image})`,
    }

    return (
        <div className="inventory-item-container">
            <div >
                <input 
                    style={Style}
                    disabled={!selected?disable:false}
                    id={id} 
                    type="checkbox" 
                    //checked={selected}
                    onChange={(e) => {
                        onChange(e.target.checked,index)
                        setSelected(e.target.checked)
                    }}/>
                    
                <label className={!selected?(disable?"checkbox-disabled checkbox":"checkbox"):"checkbox"}  htmlFor={id} style={Style}> </label>
            </div>
            <p className="font-weight-light item-tittle">{tittle}</p>
            
        </div>   
    );
}
  
export default InevntoryItem;