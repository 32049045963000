import React, { Suspense, useState, useEffect, useRef,useContext } from 'react';
import InventoryModal from '../InventoryModal/InventoryModal'
import InventoryUseModal from '../InventoryModal/InventoryUseModal'
import Introduction from '../ViewIntroduction/Introduction'
import Camera from '../Camera/Camera';
import Button from '../Button/Button'
import Avatar from '../Avatar/Avatar'
import Timer from '../Timer/Timer'
import './Game.css'
import $ from 'jquery';

import app from '../../FirebaseConfig';
import { AuthContext } from "../Authentication/Auth"; 

const Game = ({currentMoment})=> {
    const avatarRef = useRef();
    const cameraRef = useRef();
    const timerRef = useRef();

    const { currentUser } = useContext(AuthContext);
    const database = app.database();
	const momentsRef = database.ref('momentos');

    const [Momentos, setMomentos] = useState([]);
    const [MomentosCount, setMomentosCount] = useState(0);
    const [finishAssessment, setFinishAssessment] = useState(false);
    const [inventory, setInventory] = useState([]);

    const [timeFinalized, setTimeFinalized] = useState(false);
    const [capturing, setCapturing] = useState(false);

    //estados para cambiar de etapa en cada momento
    const [etapaContexto, setEtapaContexto] = useState(false);
    const [etapaSeleccion, setEtapaSeleccion] = useState(false);
    const [etapaDemanda, setEtapaDemanda] = useState(false);
    const [etapaGrabacion, setEtapaGrabacion] = useState(false);
    const [finishMomento, setFinishMomento] = useState(false);

    const [uploading, setUploading] = useState(false);
    const [talking, setTalking] = useState(false);

    useEffect(() => {
        console.log("iniciando")
        
        momentsRef.once('value', (snapshot) => {
            let momentsArray = []
			let fbmoments = snapshot.val();
            /*for(let key in fbmoments) {
                var infoJSON = fbmoments[key];
                momentsArray.push(infoJSON);
            }*/
            snapshot.forEach(childSnapshot => {
                const key = childSnapshot.key;
                const data = childSnapshot.val();
                momentsArray.push( { id: key, ...data } );
            });

            if(currentMoment === momentsArray.length){
                //app.auth().signOut()
                setFinishAssessment(true)
                console.log("Se acabo el assessment")
            }else{
                setMomentosCount(currentMoment);
            }

            setMomentos(momentsArray);
		});

        database.ref(
            `respuestas/${currentUser.uid}/`
        ).update({usuario: currentUser.email});
        
        //setTimeout(()=>{ setTimeFinalized(true); console.log("se acabo el tiempo") }, 30000);
	}, []);

    useEffect(()=>{
        if(Momentos.length > 0 ){
            if(Momentos[MomentosCount].audiosInicio){
                console.log("reproducir el video de contexto")
                //avatarRef.current.charSay();
            }
            else{
                console.log("No hay video de contexto")
            }
        }
        else{
            console.log("No existen datos para empezar el assessment")
        }
    }, [Momentos]);


    const clickContinue = ()=>{
        timerRef.current.reanudar()
        if(!etapaContexto){
            if(Momentos[MomentosCount].audiosInicio){
                avatarRef.current.charSay();
            }
            else{
                if( Momentos[MomentosCount].tipo==="seleccion"){
                    setEtapaContexto(true);
                    $('#inventoryModal').modal('show')
                }else if( Momentos[MomentosCount].tipo==="uso"){
                    setEtapaContexto(true);
                    console.log("uso...")
                    //Agregar etapa seleccion de inventario
                    //$('#inventoryUseModal').modal('show')
                    setEtapaSeleccion(true)
                }
            }
            
        }
        if(etapaContexto && !etapaSeleccion){
            if( Momentos[MomentosCount].tipo==="seleccion"){
                $('#inventoryModal').modal('show')
            }else if( Momentos[MomentosCount].tipo==="uso"){
                //setEtapaSeleccion(true);
                //$('#inventoryUseModal').modal('show')
            }
        }
        if(etapaContexto && etapaSeleccion && !etapaDemanda){
            setEtapaDemanda(true);
        }

        if(etapaContexto && etapaSeleccion && etapaDemanda && !etapaGrabacion){
            if(!capturing){
                cameraRef.current.startRecording()
                
            }else{
                cameraRef.current.stopRecording()
                if(!timeFinalized){
                    avatarRef.current.changeAvatarVideo()
                    avatarRef.current.charSay();
                }
                

            }
        }

        if(etapaContexto && etapaSeleccion && etapaDemanda && etapaGrabacion && !finishMomento){
            cameraRef.current.sendInformation();
        }
    }

    const pauseInRec =()=>{
        if(capturing){
            cameraRef.current.stopRecording()
        }
    }


    useEffect(() => {
        if(etapaContexto){
            console.log("contexto finalizado") 
        }
    }, [etapaContexto]);

    useEffect(() => {
        if(etapaSeleccion){
            console.log("selección finalizado")
            avatarRef.current.charSay();
        }
    }, [etapaSeleccion]);

    useEffect(() => {
        if(etapaDemanda){
            console.log("demanda finalizado")
        }
    }, [etapaDemanda]);

    useEffect(() => {
        if(etapaGrabacion){
            console.log("grabación finalizado")
            if(Momentos[MomentosCount].audiosFinal){
                avatarRef.current.changeFinalVideo();
                avatarRef.current.charSay();
            }
        }
    }, [etapaGrabacion]);


    useEffect(() => {
        
        if(finishMomento){
            console.log("grabación terminada")
            setEtapaContexto(false);
            setEtapaSeleccion(false);
            setEtapaDemanda(false);
            setEtapaGrabacion(false);
            setFinishMomento(false)
            //console.log(finishMomento)
            setTimeFinalized(false)

            setTimeout(function(){ 
                setUploading(false);
                if(MomentosCount === Momentos.length-1){
                    //app.auth().signOut()
                    setFinishAssessment(true)
                }else{
                    //setMomentosCount(MomentosCount+1);
                    //console.log("sumando momento")
                }
            }, 3000);

            if(MomentosCount != Momentos.length-1){
                setMomentosCount(MomentosCount+1);
            }
            
            
        }
    }, [finishMomento]);


    const loaderDiv =   <div style={{width: "100%", height:"100%", display:"flex" , justifyContent:"center", alignItems:"center", flexDirection:"column"}}>
                            {!finishMomento && <div className="spinner-border text-info" style={{width: "3rem", height:"3rem"}} role="status">
                                <span className="sr-only">Cargando...</span>
                            </div>}
                            {finishMomento? 
                                <h6 style={{margin:"1rem"}}>¡ Respuesta enviada correctamente !</h6> : 
                                <h6 style={{margin:"1rem"}}>{uploading? "Enviando respuesta..." : ""}</h6>}
                             
                        </div>

    if(Momentos.length > 0){
        if (uploading){
            return(
                loaderDiv
            )
        } else{

            return (
                <div >
                    {finishAssessment?<div className="finish-text"><h1>¡El assessment ha finalizado! Se ha completado el viaje hasta la Aldea El Sagrado Corazón. <br></br><br></br> 
                    Gracias por tu participación, ya puedes cerrar sesión con el botón correspondiente en la parte superior. Tus respuestas han sido guardadas y enviadas, un evaluador las revisará y dará su respectiva retroalimentación del ejercicio.</h1></div>:
                    <div id="game-section">
                        {/* {etapaContexto?(
                                <Timer  
                                seconds={Momentos[MomentosCount].limiteTiempo}
                                setTimeFinalized = {setTimeFinalized}></Timer>
                            ):""} */}

                        <Timer  
                                seconds={Momentos[MomentosCount].limiteTiempo}
                                setTimeFinalized = {setTimeFinalized}
                                pauseInRec = {pauseInRec}
                                ref={timerRef}></Timer>
                        <div className="context-container">
                            <div className="avatar-container fadeIn second">
                                <Avatar
                                    setTalking={setTalking}
                                    onSayEnd={!etapaContexto?  setEtapaContexto : setEtapaDemanda}
                                    ref={avatarRef}
                                    etapaGrabacion = {etapaGrabacion}
                                    finalText = {Momentos[MomentosCount].textoFinal}
                                    srcVideosLoop={Momentos[MomentosCount].audiosLoop.audios}
                                    inventory={inventory}
                                    tipo={Momentos[MomentosCount].tipo}
                                    srcVideosFinal={Momentos[MomentosCount].audiosFinal?Momentos[MomentosCount].audiosFinal.audios:null}
                                    ></Avatar>
                            </div>
    
                            {!etapaDemanda?(
                                <p id="text-context" className="h6 font-weight-normal text-justify fadeIn first" style={{color: "#1a1b1c"}}> 
                                    {Momentos[MomentosCount].textoContexto}
                                </p>
                            ):(
                                <Camera 
                                    ref={cameraRef}
                                    setTalking={setTalking}
                                    setEtapaGrabacion={setEtapaGrabacion} 
                                    finish={setFinishMomento}
                                    setCapturing={setCapturing}
                                    setUploading={setUploading}
                                    timeFinalized = {timeFinalized}
                                    moment = {MomentosCount}></Camera>
                            )}
                            
    
                            <div className="objetivo-container">
                                <h3 style={{margin:"0rem"}}>objetivo</h3>
                                <p style={{color: "#1a1b1c", margin:"1em", textAlign:"center"}}>
                                    {Momentos[MomentosCount].objetivo}
                                </p>
                            </div>
                        </div>
                        
                        <Button 
                            text={!etapaGrabacion? (etapaDemanda? capturing? "Detener grabación":"Iniciar grabación" : "Continuar"):"Enviar y continuar"} 
                            disabled={talking} 
                            margin="2rem" 
                            onClick={clickContinue}
                            color= {capturing? "#b23b3b" : "#457b9d"}
                        ></Button>
            
                        <div className="game-buttons-container">
                            <button type="button" className="btn btn-warning" 
                            disabled={Momentos[MomentosCount].tipo==="seleccion"?!etapaDemanda:!etapaContexto} data-toggle="modal" 
                            data-target={Momentos[MomentosCount].tipo==="seleccion"?"#inventoryModal":"#inventoryUseModal"} >
                                INVENTARIO
                            </button>
                            
                        </div>
                    
                        {/*Modal de selección*/} 
                        <InventoryModal 
                            tipo={Momentos[MomentosCount].tipo} 
                            limiteElementosSeleccionar={Momentos[MomentosCount].limiteElementosSeleccionar} 
                            setSelection={setEtapaSeleccion}
                            setInventory={setInventory}
                        ></InventoryModal>

                        {/*Modal del inventario*/} 
                        <InventoryUseModal 
                            tipo={Momentos[MomentosCount].tipo} 
                            limiteElementosSeleccionar={Momentos[MomentosCount].limiteElementosUsar} 
                            setSelection={setEtapaSeleccion}
                            inventory={inventory}
                            setInventory={setInventory}
                            moment = {MomentosCount}
                        ></InventoryUseModal>
                    </div>
                    }   
                </div>
            );
        }
        
        
    }
    else{
        return loaderDiv
    }
    
}
  
export default Game;