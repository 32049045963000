import './Button.css'

const Button = ({text , color, margin, padding, onClick, disabled})=> {
    const Style = {
        margin: margin? margin:0,
        padding: padding || "15px 40px",
        backgroundColor: color? color:"#457b9d"
    };

    return (
        <button 
            type="submit" 
            className="appButton fadeIn fourth" 
            style={Style}
            onClick={onClick}
            disabled={disabled?disabled:false}>
                
            {text}
        </button>
    );
}
  
export default Button;