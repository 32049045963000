import React, { useEffect, useState } from "react";
import app from "../../FirebaseConfig";

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [pending, setPending] = useState(true);

  useEffect(() => {
    app.auth().onAuthStateChanged((user) => {
      setCurrentUser(user)
      setPending(false)
    });
  }, []);

  if(pending){
    return( 
        <div style={{width: "100%", height:"100%", display:"flex" , justifyContent:"center", alignItems:"center", backgroundColor: "#DEDDDE"}}>
            <div className="spinner-border text-info" style={{width: "3rem", height:"3rem"}} role="status">
                <span className="sr-only">Cargando...</span>
            </div>
        </div>
    )
  }

  return (
    <AuthContext.Provider
      value={{
        currentUser
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};