import React from 'react'
import { useState, useEffect, forwardRef, useRef, useImperativeHandle } from 'react';

const Timer = forwardRef(({ seconds, setTimeFinalized, pauseInRec}, ref) => {
    const [timeLeft, setTimeLeft] = useState(seconds);
    const [minutos, setMinutos] = useState(Math.floor((seconds / (60)) % 60));
    const [segundos, setSegundos] = useState(Math.floor((seconds) % 60));
    const [paused, setPaused] = useState(false);
    const [srcIcon, setSrcIcon] = useState("pausa.png");
    
  
    useEffect(() => {
      if (!timeLeft){
        setTimeFinalized(true)
        return;
      } 

      const intervalId = setInterval(() => {
        if(!paused){
          setTimeLeft(timeLeft - 1);
          setMinutos(Math.floor(((timeLeft-1) / (60)) % 60));
          setSegundos( Math.floor((timeLeft-1) % 60));
        }
        
      }, 1000);
  
      return () => clearInterval(intervalId);

    }, [timeLeft]);

    const pauseTime = ()=>{
      if(paused){
        setTimeLeft(timeLeft-1);
        setPaused(false)
        setSrcIcon("pausa.png")
      }else{
        setTimeLeft(timeLeft+1);
        setPaused(true)
        setSrcIcon("play.png")
        //stop recording
        pauseInRec()
      }
  }

  useImperativeHandle(ref, () => ({
    reanudar: ()=> {
      if(paused){
        setTimeLeft(timeLeft-1);
        setPaused(false)
        setSrcIcon("pausa.png")
      }
        /* setPlaying(true);
        videoRef.current.play(); */
    },
}));
  
    return (
      <div>
        {/*<h1>{minutos>0? minutos + "min " + segundos + "seg" : segundos + "seg"}</h1>*/}
        {timeLeft!=0?
        <div>
          <div style={{display:"flex"}}>
            <button style={{border:"none", background:"none", marginRight:"0.5em", zIndex:3}} onClick={pauseTime}>
              <img style={{height:"1.5em", opacity:0.7}} src={process.env.PUBLIC_URL+"/Images/Iconos/"+srcIcon} /></button>
            <h1 style={{color: "#457b9d", marginBottom:0, minWidth:"3em"/*  height: "1rem" */}}>{ minutos  + " : " + (segundos<10? "0" + segundos : segundos) }</h1>
          </div>
          <p style={{margin:0,}}>Tiempo mínimo</p>
        </div>:
        <h3 style={{color: "#b23b3b", /* height: "1rem" */}}>{  "Tiempo completo"}</h3>}
        
      </div>
    );
  });

export default Timer;