import React, { useEffect, useState, useContext } from 'react';
import InventoryItem from '../InventoryItem/InventoryItem';
import Button from '../Button/Button';
import './InventoryModal.css'
import $ from 'jquery';

import app from '../../FirebaseConfig';
import { AuthContext } from "../Authentication/Auth"; 

const InventoryUseModal = ({limiteElementosSeleccionar, setSelection, tipo, inventory, setInventory, moment})=> {

    const database = app.database();
    const { currentUser } = useContext(AuthContext);

    const [selectedElements, setselectedElements] = useState(0);
    const [idItems, setIdItems] = useState([])
    
    
    const setSelectedItems = (value, id)=>{

        //Agrego uno al contador de elementos seleccionados
        if(value){
            if(selectedElements < limiteElementosSeleccionar){
                setselectedElements(selectedElements+1)
                idItems.push(inventory[id].id)
            }
        }
        else{
            setselectedElements(selectedElements-1)
            var x = idItems.indexOf( inventory[id].id );
 
            if ( x !== -1 ) {
                idItems.splice( x, 1 );
            }
        }
        //console.log(idItems)
        //Modifico el arreglo eliminando los elementos seleccionados
        /* let newInventoryArr = [...inventory]; 
        newInventoryArr.splice(id,1)
        setInventory(newInventoryArr) */
        

    }

    const elementsReady = ()=>{
        $('#inventoryUseModal').modal('hide')
        setSelection(true);
        
        let newInventoryArr = [...inventory];
        let usedElementsFirebase = []

        idItems.map((item,i)=>{

            const condition = (element) => element.id === item;
            var x =newInventoryArr.findIndex(condition);

            const found = newInventoryArr.find(element => element.id === item);
            if(found){
                usedElementsFirebase.push(found);
            }

            if ( x !== -1 ) {
                newInventoryArr.splice( x, 1 );
            }


        })
        setInventory(newInventoryArr)
        setIdItems([])

        if(usedElementsFirebase.length > 0){
            database.ref(
                `respuestas/${currentUser.uid}/momento${moment+1}/`
            ).update({elementos_usados: usedElementsFirebase});
        }
        

    }

    return (
        <div id="inventory-section">
             
            <div className="modal fade bd-example-modal-lg" id="inventoryUseModal" data-backdrop="static" data-keyboard="false" tabIndex="-1" aria-labelledby="inventoryModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                    <div className="modal-header">
                        <div>
                            <h5 className="modal-title" id="inventoryModalLabel">Puedes usar hasta {limiteElementosSeleccionar} elementos de tu inventario</h5>
                            <p>Los elementos que uses se consumirán y no los podrás utilizar luego</p>
                        </div>
                        
                        <button type="button" className="close" 
                            data-dismiss="modal" aria-label="Close" 
                            /* disabled={!(selectedElements==limiteElementosSeleccionar)} 
                            onClick={tipo=="seleccion"?elementsReady:null} */
                            style={{display:"inline-block"}}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body inventory-container">
                        {inventory.map((item,i) => 
                            <InventoryItem key={item.tittle} index={i} id={item.tittle} tittle={item.tittle} image={item.image} disable={limiteElementosSeleccionar == selectedElements} onChange={setSelectedItems}></InventoryItem>
                        )}

                    </div>
                    <div className="modal-footer " >
                        <p><small>Íconos tomados de www.flaticon.es</small></p>
                        Seleccionados: {selectedElements}/{limiteElementosSeleccionar}
                        {/*<Button text="Cerrar" color="#A9A9A9" margin="0 1rem" padding="10px 20px" onClick={()=>$('#inventoryModal').modal('hide')}></Button>*/}
                        <Button text="Usar" margin="0 0 0 3rem" padding="10px 20px" disabled={!(selectedElements<=limiteElementosSeleccionar)} onClick={elementsReady}></Button>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
  
export default InventoryUseModal;