
import React, {useState, useContext } from 'react';
import InventoryItem from '../InventoryItem/InventoryItem';
import Button from '../Button/Button';
import './InventoryModal.css'
import $ from 'jquery';

import app from '../../FirebaseConfig';
import { AuthContext } from "../Authentication/Auth"; 

const InevntoryModal = ({limiteElementosSeleccionar, setSelection, tipo, setInventory})=> {

    const database = app.database();
    const { currentUser } = useContext(AuthContext);

    const [selectedElements, setselectedElements] = useState(0);
    const [inventoryItems, setInventoryItems] = useState([
        {id: "item1", tittle: "Brújula", image: process.env.PUBLIC_URL+"/Images/Inventory/brujula.png", selected: false, used: false}, 
        {id: "item2", tittle: "Encendedor", image: process.env.PUBLIC_URL+"/Images/Inventory/encendedor.png", selected: false, used: false},
        {id: "item3", tittle: "5 litros de agua", image: process.env.PUBLIC_URL+"/Images/Inventory/agua.png", selected: false, used: false},
        {id: "item4", tittle: "Cuerda de 10 metros", image: process.env.PUBLIC_URL+"/Images/Inventory/cuerda.png", selected: false, used: false},
        {id: "item5", tittle: "Manta o cobija", image: process.env.PUBLIC_URL+"/Images/Inventory/manta.png", selected: false, used: false},
        {id: "item6", tittle: "6 enlatados de atún", image: process.env.PUBLIC_URL+"/Images/Inventory/comida-enlatada.png", selected: false, used: false},
        {id: "item7", tittle: "6 barras energéticas", image: process.env.PUBLIC_URL+"/Images/Inventory/barra-energetica.png", selected: false, used: false},
        {id: "item8", tittle: "Botiquín de primeros auxilios", image: process.env.PUBLIC_URL+"/Images/Inventory/botiquin-medico.png", selected: false, used: false}
    ])
    
    
    const setSelectedItems = (value, id)=>{
        console.log(id)
        //Agrego uno al contador de elementos seleccionados
        if(value){
            if(selectedElements < limiteElementosSeleccionar){
                setselectedElements(selectedElements+1)
            }
        }
        else{
            setselectedElements(selectedElements-1)
        }

        //seteo el estado seleccionado del item para saber cuales quedaran seleccionados para el resto del juego
        let newInventoryArr = [...inventoryItems]; 
        newInventoryArr[id].selected = value; 
        setInventoryItems(newInventoryArr);
        //
        


    }

    const elementsReady = ()=>{
        $('#inventoryModal').modal('hide')
        setSelection(true);

        let SelectedItems = [];
        inventoryItems.map(item =>{
            if(item.selected){
                SelectedItems.push({tittle: item.tittle, image: item.image, id:item.id});
            }
        })
        
        database.ref(
			`respuestas/${currentUser.uid}/momento1/`
		).update({elementos_seleccionados: SelectedItems});

        setInventory(SelectedItems)
    }

    return (
        <div id="inventory-section">
             
            <div className="modal fade bd-example-modal-lg" id="inventoryModal" data-backdrop="static" data-keyboard="false" tabIndex="-1" aria-labelledby="inventoryModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="inventoryModalLabel">Elige {limiteElementosSeleccionar} elementos para tu inventario</h5>
                        <button type="button" className="close" 
                            data-dismiss="modal" aria-label="Close" 
                            disabled={!(selectedElements===limiteElementosSeleccionar)} 
                            onClick={tipo==="seleccion"?elementsReady:null}
                            style={{display:(selectedElements===limiteElementosSeleccionar)?"inline-block":'none'}}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body inventory-container">
                        {inventoryItems.map((item,i) => 
                            <InventoryItem key={i} index={i} id={item.id} tittle={item.tittle} image={item.image} disable={limiteElementosSeleccionar === selectedElements} onChange={setSelectedItems}></InventoryItem>
                        )}

                    </div>
                    <div className="modal-footer " >
                        <p><small>Íconos tomados de www.flaticon.es</small></p>
                        Seleccionados: {selectedElements}/{limiteElementosSeleccionar}
                        {/*<Button text="Cerrar" color="#A9A9A9" margin="0 1rem" padding="10px 20px" onClick={()=>$('#inventoryModal').modal('hide')}></Button>*/}
                        <Button text="Continuar" margin="0 0 0 3rem" padding="10px 20px" disabled={!(selectedElements===limiteElementosSeleccionar)} onClick={elementsReady}></Button>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
  
export default InevntoryModal;