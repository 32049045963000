import React, { useState, useEffect } from 'react';
import Button from '../Button/Button'
import './Introduction.css'
import '../../Animations.css'
import InstructionsModal from '../InstructionsModal/InstructionsModal'
import $ from 'jquery';

const Introduction = ({onVideosEnded})=> {

    const [videoEnded, setvideoEnded] = useState(false);
    const [videoCount, setvideoCount] = useState(1);
    const [videosEnded, setVideosEnded] = useState(false);
    const [finalized, setFinalized] = useState(false);

    useEffect(()=>{
        onVideosEnded(finalized)
    }, [finalized]);

    const updateVideo = ()=>{
        if(videoCount < 6){
            setvideoEnded(false);
            setvideoCount(videoCount+1)
        }
        else{
            //$('#InstructionsModal').modal('show')
            //onVideosEnded(true)

            setVideosEnded(true);
        } 
    }

    const goBack = ()=>{
        if(videoCount > 1){
            setvideoEnded(false);
            setvideoCount(videoCount-1);
            console.log("regresando...")
        }
    }

    return (
        !videosEnded?
        <div className="introduction-container">
            <video 
                src={process.env.PUBLIC_URL+"/Videos/Introduction/"+ videoCount +".mp4"} 
                controls 
                autoPlay={true}
                width="100"
                className="introduction-video fadeIn second"
                onEnded={() => setvideoEnded(true)}></video>
            <div>
                {videoCount!=1&&<Button text="Regresar" margin="1rem" onClick={goBack} color="#b23b3b"></Button>}
                <Button text="Continuar" margin="1rem" disabled={!videoEnded} onClick={updateVideo}></Button>
            </div>

            
            
        </div>:
        <InstructionsModal 
            setFinal={setFinalized}
        ></InstructionsModal>
    );
}
  
export default Introduction;