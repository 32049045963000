import React, { useEffect, useState, forwardRef, useRef, useImperativeHandle } from 'react';
import './Avatar.css';

const Sofia_Idle = process.env.PUBLIC_URL+"/Videos/Dialogs/Sofia_Idle.mp4";
const Roberta_Idle = process.env.PUBLIC_URL+"/Videos/Dialogs/Roberta_Idle.mp4";

const Avatar = forwardRef(({srcVideosLoop, etapaGrabacion, finalText, onSayEnd, setTalking, inventory, tipo, srcVideosFinal}, ref) =>{

    const [videosArray, setVideosArray] = useState([])
    const [videosFinalArray, setVideosFinalArray] = useState([])
    const [videoCount, _setVideoCount] = useState(0)
    const [playing, setPlaying] = useState(false)
    const [first, _setFirst] = useState(0)
    const [finalVideo, setFinalVideo] = useState(false)
    const videoRef = useRef(null);
    const firstRef = useRef(first);
    const videoCountRef = useRef(videoCount);

    const setFirst = data => {
        firstRef.current = data;
        _setFirst(data);
    };

    const setVideoCount = data => {
        videoCountRef.current = data;
        _setVideoCount(data);
    };

    useEffect(() => {

        videoRef.current.addEventListener('play', () =>{
            if(!videoRef.current.src.includes("Videos/Dialogs/Sofia_Idle.mp4") &&//!= window.location.protocol + "//" + window.location.host + Sofia_Idle &&
                !videoRef.current.src.includes("Videos/Dialogs/Roberta_Idle.mp4") ){//!= window.location.protocol + "//" + window.location.host + Roberta_Idle){
                console.log("Comenzando el dialogo");
                setTalking(true);
            }
            
        });

        videoRef.current.addEventListener('ended', () =>{
            //console.log(videoRef.current.src + " || " + window.location.protocol + "//" + window.location.host + Sofia_Idle)
            if(videoRef.current.src !== window.location.protocol + "//" + window.location.host + Sofia_Idle &&
            videoRef.current.src !== window.location.protocol + "//" + window.location.host + Roberta_Idle){
                console.log("Dialogo finalizado")
                if(tipo==="seleccion" && firstRef.current<3){
                    setVideoCount(videoCountRef.current+1)
                    setFirst(firstRef.current+1)
                    console.log("first")
                    setPlaying(true)
                    videoRef.current.play();
                }else{
                    setPlaying(false);
                    setTalking(false);
                    onSayEnd(true);
                }
            }
            
        });

        videoRef.current.addEventListener('pause', () =>{
            console.log("pausado")
        });

        let tempVideosArray = []
        for(let key in srcVideosLoop) {
            var infoJSON = srcVideosLoop[key];
            tempVideosArray.push(infoJSON);
        }
        setVideosArray(tempVideosArray);
        console.log(tempVideosArray)

        if(srcVideosFinal){
            let tempVideosArray2 = []
            for(let key in srcVideosFinal) {
                var infoJSON2 = srcVideosFinal[key];
                tempVideosArray2.push(infoJSON2);
            }
            setVideosFinalArray(tempVideosArray2);
            
        }

    }, []);

    /* useEffect(()=>{
        console.log("cambiando playing")
        console.log(videoCount)
    }, [videoCount]); */

    const getIdle = () =>{
        let videoCharSrc = ""
        if(videosArray.length>0){
            const character = videosArray[0].avatar;
            
            if(videosArray.length>0){

                if(character==="Roberta"){
                    videoCharSrc = Roberta_Idle;
                }else if(character==="Sofía"){
                    videoCharSrc = Sofia_Idle;
                }
                
                else{
                    videoCharSrc = Sofia_Idle;
                }
            }
            else{
                videoCharSrc = Sofia_Idle;
            }
            //console.log(videoCharSrc)
        }
        
        return videoCharSrc
    }


    //Método para empezar la voz mediante botones
    useImperativeHandle(ref, () => ({
        charSay: ()=> {
            setPlaying(true);
            videoRef.current.play();
        },

        changeAvatarVideo: ()=>{
            videoCount < videosArray.length-1 ?
                setVideoCount(videoCount+1) : setVideoCount(0)

            if(tipo==="seleccion" && videoCount >= videosArray.length-1 /* && videosArray[videoCount].requisito==="auto" */){
                setFirst(0)
            }
        },

        changeFinalVideo: ()=> {
            setFinalVideo(true)
        }
    }));

    const updateCount = () =>{
        videoCount < videosArray.length-1 ?
            setVideoCount(videoCount+1) : setVideoCount(0) 
        
        if(tipo==="seleccion" && videoCount >= videosArray.length-1){
            setFirst(0)
        }
    }

    const req = () =>{
        var reqFound=false

        //Busca en el inventario si tiene el item para reproducir la propuesta, si no pasará al siguiente dialogo

        if(videosArray[videoCount].requisito!=="" && videosArray[videoCount].requisito!=="auto"){
            let obj = inventory.find(item => item.tittle === videosArray[videoCount].requisito);
            if(!obj){
                //console.log(obj);
                if(tipo==="uso"){
                    reqFound=true
                }     
            }else{
                if(tipo==="seleccion"){
                    reqFound=true
                }  
            }
        }
        return reqFound
    }
    

    return (
        <div className="avatar-container-inner">
            <h3 className="character-name">{videosArray.length>0? videosArray[0].avatar: ""}</h3>
            <div className="parent">
                <video 
                    id = "AvatarVideo"
                    ref={videoRef}
                    // src={playing? process.env.PUBLIC_URL+"/Videos/Dialogs/" + videosArray[videoCount].srcVideo : getIdle()}// : process.env.PUBLIC_URL+"/Videos/Dialogs/Sofia_Idle.mp4"} 
                    src={playing? (finalVideo?process.env.PUBLIC_URL+"/Videos/Dialogs/" + videosFinalArray[0].srcVideo : (req()?  updateCount(): process.env.PUBLIC_URL+"/Videos/Dialogs/" + videosArray[videoCount].srcVideo)) : getIdle()}
                    autoPlay={true}
                    loop={playing? false : true}
                    width="100"
                    className="image2 fadeIn first"
                    //poster={process.env.PUBLIC_URL+"/Dialogs/avatares/sofia.jpg"}
                    //onEnded={() => setvideoEnded(true)}
                ></video>
            </div>    
            <p className="m-0 mt-1 fadeIn first">{etapaGrabacion?finalText:"" }</p>        
        </div>
    );
})

export default Avatar