import React, { useState, useEffect, useContext } from 'react';
import Header from '../components/Header/Header'
import Introduction from '../components/ViewIntroduction/Introduction';
import Game from '../components/ViewGame/Game';
import './Assessment.css'

import app from '../FirebaseConfig';
import { AuthContext } from "../components/Authentication/Auth"; 

function Assessment() {
  const [verifyProgressEnded, setverifyProgressEnded] = useState(false);
  const [currentMoment, setcurrentMoment] = useState(0);
  const [videosEnded, setvideosEnded] = useState(false);

  const { currentUser } = useContext(AuthContext); //currentUser.email - currentUser.uid
  
  useEffect(() => {
    const ref = app.database().ref();
    ref.child(`respuestas/${currentUser.uid}/progreso`).once("value", (snapshot) => {
      const userProgress = snapshot.val();
      if(userProgress){
        if(userProgress>0){
          setcurrentMoment(userProgress)
          setvideosEnded(true);
        }
        setverifyProgressEnded(true);

      }else{
        console.log("Aún no has empezado con el assessment");
        setverifyProgressEnded(true);
      }
      
    });

  }, []);

  const loaderDiv = <div style={{width: "100%", height:"100%", display:"flex" , justifyContent:"center", alignItems:"center", flexDirection:"column"}}>
      <div className="spinner-border text-info" style={{width: "3rem", height:"3rem"}} role="status">
          <span className="sr-only">Cargando...</span>
      </div>
    </div>

  return (
    <div className="game-container ">
      <Header></Header>
      <div className="o-container">
        {!verifyProgressEnded? loaderDiv : 
          !videosEnded? <Introduction onVideosEnded={setvideosEnded}></Introduction>:
            <Game currentMoment={currentMoment}></Game>}
        
        <div className="credits-kaleido fadeIn third">
          <p className="mb-0 mx-3 font-weight-light ">Powered By</p>
          <img src={`${process.env.PUBLIC_URL}/Images/LogoKaleido.png`} height={80} />
        </div>
      </div>
      
    </div>
    
  );
}
  
export default Assessment;